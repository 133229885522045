import React, { useState, useEffect, useRef } from 'react'
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from 'react-helmet'
import { useHistory, Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { logoActions } from '../../_actions'

import Slide from 'react-reveal/Slide'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'

const Centered = styled.div`
	padding-top: var(--size-200px);
	padding-bottom: var(--size-100px);
	.d-flex {
		overflow-x: auto;
	}
	.flexItem {
		padding: var(--size-10px) var(--size-20px);
		width: 25%;
		@media (max-width: 767px) {
			flex: 0 0 60%;
			scroll-snap-align: start;
		}
	}
	@media (max-width: 767px) {
		.d-flex {
			-webkit-overflow-scrolling: touch;
			scroll-snap-type: x mandatory;
		}
	}
`

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	th {
		padding: var(--size-20px);
		border: 1px solid #fff;
	}
	td {
		padding: var(--size-20px);
		border: 1px solid #373b3e;
	}
	thead {
		background: #373b3e;
		color: #fff;
	}
	@media (max-width: 767px) {
		th,
		td {
			font-size: var(--size-30px);
		}
	}
`

function About3({ history, props }) {
	const dispatch = useDispatch()

	useEffect(() => {
		window.scrollTo(0, 0)
		dispatch(logoActions.logo('1'))
	}, [])

	return (
		<>
			<Helmet>
				<title>진료안내</title>
			</Helmet>
			<div className='topBar'></div>
			<div className='sv'>
				<Centered className=' text-center'>
					<h1 className='font-45 color-primary mb-60'>진료안내</h1>
					<div className='row text-center'>
						<Flip left cascade>
							{/* <div className="col-md-3 mb-50">
                <img src="/img/about/about2-1.png" className="mb-20" alt="" />
                <h1 className="font-30 mb-50">
                  <strong>코수술 상담</strong>
                </h1>
                <p className="font-24 wordKeep">
                  본원에서는 수술을 받으시기 위해 상담하시는 분의 전화 예약을
                  받고 있습니다. 내원 전 상담을 원하시는 분은 전화로 예약하시면
                  상담을 받으실 수 있습니다.
                </p>
              </div>
              <div className="col-md-3 mb-50">
                <img src="/img/about/about2-1.png" className="mb-20" alt="" />
                <h1 className="font-30 mb-50">
                  <strong>보청기 상담</strong>
                </h1>
                <p className="font-24 wordKeep">
                  본원에서는 장애진단 장비부터 대학병원급 청각 장비를 구비하고
                  있습니다. 청각장애 진단 및 보청기 상담은 100% 예약으로 대기
                  없이 상담하실 수 있습니다.
                </p>
              </div>
              <div className="col-md-3 mb-50">
                <img src="/img/about/about2-1.png" className="mb-20" alt="" />
                <h1 className="font-30 mb-50">
                  <strong>
                    코수술 및<br />
                    수면무호흡 상담
                  </strong>
                </h1>
                <p className="font-24 wordKeep">
                  최첨단의 정확한 수면다원검사 장비와 3D CT를 이용하여 진단에서
                  수술적치료, 비수술적 치료가 모두 가능합니다.
                </p>
              </div> */}
							<div className='col mb-50'>
								<img src='/img/about/about2-1.png' className='mb-20' alt='' />
								<h1 className='font-30 mb-50'>
									<strong>진료 시간</strong>
								</h1>
								<p className='font-24 wordKeep'>
									평일 오전 9시부터 오후 7시 까지
									<br />( 점심시간 오후 1시부터 오후 2시까지)
									<br />
									토요일 오전 9시부터 오후 2시 까지
									<br />
									(점심 시간 없이 진료)
									<br />
									<a href='tel:02-373-0075' className='font-30'>
										02-373-0075
									</a>
								</p>
							</div>
						</Flip>
					</div>
					<div className='container'>
						<div className='row mb-50'>
							<div className='col-md-3'>
								<img
									src='/img/about/about3-1.png'
									alt=''
									className='img-fluid'
								/>
								<h1 className='dcNm text-center mt-20 color-primary'>최환</h1>
							</div>
							<div className='col-md-9'>
								<Table>
									<thead>
										<tr>
											<th>월</th>
											<th>화</th>
											<th>수</th>
											<th>목</th>
											<th>금</th>
											<th>토</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td></td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
						<div className='row mb-50'>
							<div className='col-md-3'>
								<img
									src='/img/about/about-3-6.png'
									alt=''
									className='img-fluid'
									style={{maxHeight: '255px',maxHeight: '288px', borderRadius: '100%', overflow: 'hidden'}}
								/>
								<h1 className='dcNm text-center mt-20 color-primary'>이세은</h1>
							</div>
							<div className='col-md-9'>
								<Table>
									<thead>
										<tr>
											<th>월</th>
											<th>화</th>
											<th>수</th>
											<th>목</th>
											<th>금</th>
											<th>토</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td></td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
						<div className='row mb-50'>
							<div className='col-md-3'>
								<img
									src='/img/about/about-3-5.png'
									alt=''
									className='img-fluid'
									style={{maxHeight: '255px'}}
								/>
								<h1 className='dcNm text-center mt-20 color-primary'>홍민표</h1>
							</div>
							<div className='col-md-9'>
								<Table>
									<thead>
										<tr>
											<th>월</th>
											<th>화</th>
											<th>수</th>
											<th>목</th>
											<th>금</th>
											<th>토</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>&Omicron;</td>
											<td></td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
						<div className='row mb-50'>
							<div className='col-md-3'>
								<img
									src='/img/about/about3-4.png'
									alt=''
									className='img-fluid'
								/>
								<h1 className='dcNm text-center mt-20 color-primary'>윤희철</h1>
							</div>
							<div className='col-md-9'>
								<Table>
									<thead>
										<tr>
											<th>월</th>
											<th>화</th>
											<th>수</th>
											<th>목</th>
											<th>금</th>
											<th>토</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td></td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
											<td>&Omicron;</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</Centered>
			</div>
		</>
	)
}

export default About3
