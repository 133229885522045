import React, { useState, useEffect, useRef } from "react";
// helmet은 html의 title 태그 내용을 변경 할때 사용
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoActions } from "../../_actions";

import Slide from "react-reveal/Slide";
import Rotate from "react-reveal/Rotate";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

import Rodal from "rodal";
import "rodal/lib/rodal.css";

const Centered = styled.div`
  padding-top: var(--size-200px);
  padding-bottom: var(--size-100px);
  .d-flex {
    overflow-x: auto;
  }
  .flexItem {
    padding: var(--size-10px);
  }
  .colBox {
    border: 2px solid var(--color-link);
    padding: var(--size-30px);
    min-height: 350px;
  }
  @media (max-width: 767px) {
    .imgPt {
      max-width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .d-flex {
      margin-left: -15px;
      margin-right: -15px;
    }
    .flexItem img {
      width: 200px;
    }
  }
`;

const Dcs = styled.div`
  position: relative;
  @media (max-width: 767px) {
    display: block !important;
  }
`;

const DcItem = styled.div`
  position: relative;
  padding: var(--size-10px) var(--size-10px) 0 var(--size-10px);
  .portrait {
    transition: all 0.2s ease-in-out;
    &.active {
    }
  }
  .dcBtm {
    padding-top: var(--size-20px);
    text-align: center;
    .showBtn {
      color: #fff;
      border: 0;
      outline: 0;
      background: #0076be;
      font-size: 22px;
      margin-top: var(--size-10px);
      padding: var(--size-10px) var(--size-20px);
      @media (max-width: 767px) {
        padding: 10px 20px;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: var(--size-100px);
  }
`;

const Btn = styled.button`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border: 0;
  outline: 0;
  background: transparent;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  &.active {
    opacity: 1;
    bottom: var(--size-40px);
  }
`;

const Exp = styled.div`
  .hd {
    padding: var(--size-20px);
    background: var(--color-text);
    color: #fff;
  }
  .num {
    color: #999;
    display: block;
  }
  ul {
    li {
      margin-bottom: var(--size-10px);
    }
  }
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10000;
    overflow-y: auto;
    .hd {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10001;
      padding: var(--size-40px);
    }
    .d-flex {
      padding: var(--size-40px);
    }
  }
`;

const PopupText = styled.div`
  line-height: 1.3;
  .list-mb {
    margin-bottom: 10px;
  }
`;

function About2({ history, props }) {
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(false);
  const [hoverId, setHoverId] = useState(0);

  const [pNum, setPNum] = useState(0);
  const [pOpen, setPOpen] = useState(false);

  const [closeExp, setCloseExp] = useState(true);

  const [visibleDtc, setVisibleDct] = useState(false);

  const popupOpenHandler = (e) => {
    if (e === pNum) {
      setPNum(0);
    } else {
      setPNum(e);
      setVisibleDct(true);
    }
    setCloseExp(false);
  };

  const popupCloseHandler = () => {
    setPNum(0);
    setPOpen(false);
  };

  const hoverInHandler = (id) => {
    setHoverId(id);
    setHovered(true);
  };

  const hoverOutHandler = () => {
    setHoverId(0);
    setHovered(false);
  };

  const resvClose = () => {
    setPNum(0);
    setVisibleDct(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(logoActions.logo("1"));
  }, []);

  return (
    <>
      <Helmet>
        <title>DMC 하나이비인후과 의료진 소개</title>
      </Helmet>
      <div className="topBar"></div>
      <div className="sv">
        <Centered className="wrapper">
          <h1 className="font-45">DMC 하나이비인후과</h1>
          <h1 className="font-45 color-primary mb-50">의료진 소개</h1>
          <Dcs className="d-flex justify-content-around align-items-end">
            <Slide right>
              <DcItem
                className={hovered ? "col active" : "col"}
                onMouseEnter={(e) => hoverInHandler(1)}
                onMouseLeave={() => hoverOutHandler(0)}
              >
                <img
                  src="/img/about/about3-1.png"
                  className={
                    hovered && hoverId === 1
                      ? "img-fluid portrait active"
                      : "img-fluid portrait"
                  }
                  alt=""
                />
                <div className="dcBtm">
                  <h1 style={{ fontSize: "26px" }}>대표원장 최환<br />Choe Hwan</h1>
                  <button
                    onClick={(e) => popupOpenHandler(1)}
                    type="button"
                    className="showBtn"
                  >
                    프로필 보기
                  </button>
                </div>
              </DcItem>
              {/* <DcItem
                className={hovered ? "col active" : "col"}
                onMouseEnter={(e) => hoverInHandler(2)}
                onMouseLeave={() => hoverOutHandler(0)}
              >
                <img
                  src="/img/about/about3-5_2.png"
                  style={{
                    maxHeight: 452
                  }}
                  className={
                    hovered && hoverId === 2
                      ? "img-fluid portrait active"
                      : "img-fluid portrait"
                  }
                  alt=""
                />
                <div className="dcBtm">
                  <h1 style={{ fontSize: "26px" }}>
                    원장 이영호<br />Lee Young-ho
                  </h1>
                  <button
                    onClick={(e) => popupOpenHandler(2)}
                    type="button"
                    className="showBtn"
                  >
                    프로필 보기
                  </button>
                </div>
              </DcItem> */}
              <DcItem
                className={hovered ? "active" : ""}
                onMouseEnter={(e) => hoverInHandler(3)}
                onMouseLeave={() => hoverOutHandler(0)}
              >
                <img
                  src="/img/about/about3-4.png"
                  className={
                    hovered && hoverId === 3
                      ? "img-fluid portrait active"
                      : "img-fluid portrait"
                  }
                  alt=""
                />
                <div className="dcBtm">
                  <h1 style={{ fontSize: "26px" }}>
                    원장 윤희철<br />Yoon Hee-chul
                  </h1>
                  <button
                    onClick={(e) => popupOpenHandler(3)}
                    type="button"
                    className="showBtn"
                  >
                    프로필 보기
                  </button>
                </div>
              </DcItem>
              <DcItem
                className={hovered ? "active" : ""}
                onMouseEnter={(e) => hoverInHandler(5)}
                onMouseLeave={() => hoverOutHandler(0)}
              >
                <img
                  src="/img/about/about-3-6.png"
                  className={
                    hovered && hoverId === 5
                      ? "img-fluid portrait active"
                      : "img-fluid portrait"
                  }
                  alt=""
                />
                <div className="dcBtm">
                  <h1 style={{ fontSize: "26px" }}>
                  이세은 원장<br/ >Yi Se Eun
                  </h1>
                  <button
                    onClick={(e) => popupOpenHandler(5)}
                    type="button"
                    className="showBtn"
                  >
                    프로필 보기
                  </button>
                </div>
              </DcItem>
              <DcItem
                className={hovered ? "active" : ""}
                onMouseEnter={(e) => hoverInHandler(4)}
                onMouseLeave={() => hoverOutHandler(0)}
              >
                <img
                  src="/img/about/about-3-5.png"
                  className={
                    hovered && hoverId === 4
                      ? "img-fluid portrait active"
                      : "img-fluid portrait"
                  }
                  alt=""
                />
                <div className="dcBtm">
                  <h1 style={{ fontSize: "26px" }}>
                    원장 홍민표 <br />Hong Min-pyo
                  </h1>
                  <button
                    onClick={(e) => popupOpenHandler(4)}
                    type="button"
                    className="showBtn"
                  >
                    프로필 보기
                  </button>
                </div>
              </DcItem>
            </Slide>
          </Dcs>
          <Rodal
            visible={visibleDtc}
            onClose={resvClose}
            animation="slideUp"
            closeOnEsc
            customStyles={{
              borderRadius: "5px",
              height: "auto",
              bottom: "auto",
              width: "500px",
              top: "50px",
              bottom: "50px",
              overflowY: "auto",
              border: "5px solid #0076BE",
            }}
          >
            {pNum === 1 ? (
              <PopupText className=" text-center">
                <img
                  src="/img/about/dct-1.png"
                  className="center-block"
                  alt="최환 원장"
                />
                <p className="font-24 mt-50">
                  <strong>대표원장 최환 Choe Hwan</strong>
                </p>
                <p className="font-20 color-primary mt-50">
                  저희 DMC하나이비인후과의료진은 귀.코.목 주치의로 항상
                  찾아주시는 환자분들에게 성심을 다하여 진료하겠습니다.
                </p>
                <p className="font-24 mt-50">
                  <strong>학력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">고려대학교 의과대학 졸업</li>
                  <li>고려대학교 의학대학원 대학원 졸업</li>
                </ul>
                <p className="font-24 mt-50">
                  <strong>경력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">
                    현 고려대학교 의료원 이비인후과 외래교수
                  </li>
                  <li className="list-mb">
                    고려대학교 안암병원 이비인후과 임상교수
                  </li>
                  <li className="list-mb">고려대학교 안암병원 전임의</li>
                  <li className="list-mb">
                    고려대학교 안암병원 인턴 및 레지던트 수료
                  </li>
                  <li className="list-mb">전 대한이비인후과 의사회 보험위원</li>
                  <li>현 대한이비인후과 의사회 상임이사</li>
                </ul>
                <p className="font-24 mt-50">
                  <strong>학회활동</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">대한이비인후과학회 정회원</li>
                  <li className="list-mb">대한비과학회 정회원</li>
                  <li className="list-mb">대한이과학회 정회원</li>
                  <li className="list-mb">대한소아이비인후과학회 정회원</li>
                  <li className="list-mb">대한수면학회 정회원</li>
                  <li className="list-mb">대한천식 및 알레르기학회 정회원</li>
                  <li className="list-mb">대한갑상선학회 정회원</li>
                  <li className="list-mb">대한갑상선두경부외과학회 정회원</li>
                  <li className="list-mb">대한후두음성언어의학회 정회원</li>
                  <li className="list-mb">대한기관식도과학회 정회원</li>
                  <li>대한수면호흡학회 정회원</li>
                </ul>
                <p className="font-24 mt-50">
                  <strong>학술활동 및 해외발표</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">
                    2009 28th ERS 유럽이비인후과학회 구연발표
                  </li>
                  <li className="list-mb">
                    2013 20th IFOS World Congress 세계이비인후과학회 구연발표
                  </li>
                  <li className="list-mb">
                    고려대학교 Head and Neck Dissection Course 연수
                  </li>
                  <li className="list-mb">
                    고려대학교 귀수술 및 Temporal Bone Dissection Course 연수
                  </li>
                  <li className="list-mb">
                    고려대학교 코수술 및 Endoscopic Sinus Surgery(부비동 내시경
                    수술)연수
                  </li>
                  <li className="list-mb">
                    서울대학교 코수술 및 Endoscopic Sinus Surgery(부비동 내시경
                    수술)연수
                  </li>
                  <li className="list-mb">고려대학교 보청기 과정 연수</li>
                  <li>서울삼성병원 보청기 과정 연수</li>
                </ul>
                <p className="font-24 mt-50">
                  <strong>제1저자</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">
                    The Usefulness of CO2 Laser-Assisted Phonomicrosurgery Using
                    a Computer-Guided Scanner in Broad-Based Vocal Polyp. Korean
                    J Otorhinolaryngol-Head Neck Surg.{" "}
                    <span className="num">2013;56:511-5</span>
                  </li>
                  <li className="list-mb">
                    Usefulness of the Harmonic Scalpel in Thyroid Surgery J
                    Korean Thyroid Assoc 2012 Nov; 5(02) 138-142{" "}
                    <span className="num">
                      대한갑상선학회 2013년 우수논문 수상
                    </span>
                  </li>
                  <li className="list-mb">
                    A Case of Schwannoma of the Nasal Cavity Mimicking Olfactory
                    Neuroblastoma Korean J Otolaryngol{" "}
                    <span className="num">2007;50(6):548-51</span>
                  </li>
                  <li className="list-mb">
                    Complete laryngopharyngeal stricture due to lye ingestion.
                    Korean Journal of Bronchoesophagology{" "}
                    <span className="num">2007;1(13);55-8</span>
                  </li>
                </ul>
                <p className="font-24 mt-50">
                  <strong>공동저자</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">
                    Prevalence and Characteristics of Positional Sleep Apnea in
                    Korea. Korean J Otorhinolaryngol-Head Neck Surg{" "}
                    <span className="num">2009;52(5):407-12</span>
                  </li>
                  <li className="list-mb">
                    Complete laryngopharyngeal stricture due to lye ingestion.
                    Korean Journal of Bronchoesophagology{" "}
                    <span className="num">2007;1(13);55-8</span>
                  </li>
                  <li className="list-mb">
                    Expression and distribution patterns of the stem cell
                    marker, nestin, and the stem cell renewal factor, BMI-1, in
                    normal human nasal mucosa and nasal polyps. Acta
                    Otolaryngol. 2009{" "}
                    <span className="num">Sep;129(9):996-1001</span>
                  </li>
                  <li className="list-mb">
                    Increased expression of glycoprotein 340 in the ethmoid
                    sinus mucosa of patients with chronic sinusitis. Arch
                    Otolaryngol Head Neck Surg. 2007{" "}
                    <span className="num">Nov;133(11):1111-4.</span>
                  </li>
                  <li className="list-mb">
                    Increased expression of glycoprotein 340 in the ethmoid
                    sinus mucosa of patients with{" "}
                    <span className="num">
                      Over-expression of neuropeptide urocortin and its
                      receptors in human allergic nasal mucosa.
                    </span>
                  </li>
                  <li>
                    D2-40 immunohistochemical assessment of lymphangiogenesis in
                    normal and edematous sinus mucosa and nasal polyp.
                    Laryngoscope.{" "}
                    <span className="num">2007 Mar;117(3):442-6</span>
                  </li>
                </ul>
              </PopupText>
            ) : null}
            {pNum === 2 ? (
              <PopupText className=" text-center">
                <img
                  src="/img/about/about3-5_3.png"
                  className="center-block"
                  style={{borderRadius: '100%',overflow:' hidden', width: 'auto', height: '200px'}}
                  alt="이영호 원장"
                />
                <p className="font-24 mt-50">
                  <strong>이영호 원장 Lee Young-ho</strong>
                </p>
                <p className="font-24 mt-50">
                  <strong>학력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">연세대학교 의학전문대학원 졸업 </li>
                  <li className="list-mb">
                  신촌세브란스 병원 인턴 및 이비인후과 레지던트 수료</li>
                </ul>
                <p className="font-24 mt-50 mb-20">
                  <strong>학회활동</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">전) 프레쉬이비인후과 원장</li>
                  <li className="list-mb">대한이비인후과학회 / 대한비과학회 / 대한이과학회</li>
                  <li className="list-mb">대한평형의학회 / 대한수면의학회 /대한수면호흡학회 정회원</li>
                  <li className="list-mb">수면다원검사 정도관리위원회 수면인증의</li>
                </ul>
              </PopupText>
            ) : null}
            {pNum === 3 ? (
              <PopupText className=" text-center">
                <img
                  src="/img/about/dct-3.png"
                  className="center-block"
                  alt="윤희철 원장"
                />
                <p className="font-24 mt-50">
                  <strong>윤희철 원장 Yoon Hee-chul</strong>
                </p>
                <p className="font-24 mt-50 mb-20">
                  <strong>주요경력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li>고려대학교 의과대학 졸업</li>
                  <li>고려대학교 의료원 인턴 수료</li>
                  <li>고려대학교 의료원 안산병원 이비인후과 레지던트 수료</li>
                  <li>이비인후과 전문의 취득</li>
                  <li>국군춘천병원 이비인후과 과장</li>
                </ul>
                <p className="font-24 mt-50 mb-20">
                  <strong>학회활동</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">대한이비인후과 학회 정회원</li>
                  <li className="list-mb">대한비과학회 정회원</li>
                  <li className="list-mb">대한이과학회 정회원</li>
                  <li className="list-mb">대한소아이비인후과 학회 정회원</li>
                  <li>대한갑상선두경부외과학회 정회원</li>
                </ul>
                <p className="font-24 mt-50 mb-20">
                  <strong>학술활동 및 수상경력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">
                    2016년도 고려대학교 의료원 해외학회지원 우수전공의 수상
                  </li>
                  <li className="list-mb">
                    제90차 대한이비인후과 학술대회 전공의 우수연제상 수상
                    <br />The Impact of Nasal Polyposis on Olfactory
                    Dysfunction in Chronic Rhinosinusitis.
                  </li>
                  <li className="list-mb">
                    제 64차 전문의 자격시험 2차시험 수석
                  </li>
                  <li className="list-mb">
                    2017 Asia-Pacific Society of Thyroid Surgery 참석
                  </li>
                  <li className="list-mb">
                    고려대학교 두경부 술기 워크샵 수료
                  </li>
                  <li className="list-mb">
                    고려대학교 내시경 부비동 수술 연수회 수료
                  </li>
                  <li className="list-mb">
                    고려대학교 측두골 수술 연수회 수료
                  </li>
                  <li className="list-mb">
                    하나 부비동 내시경 수술 해부 워크샵 수료
                  </li>
                  <li>4th SMC ENT Microvascular Workshop 수료</li>
                </ul>
              </PopupText>
            ) : null}
            {pNum === 4 ? (
              <PopupText className=" text-center">
                <img
                  src="/img/about/dct-4.png"
                  className="center-block"
                  style={{maxWidth: '100%'}}
                  alt="홍민표  원장"
                />
                <p className="font-24 mt-50">
                  <strong>홍민표  원장 Hong Min-pyo</strong>
                </p>
                <p className="font-24 mt-50 mb-20">
                  <strong>주요경력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li>연세대학교 의과대학 졸업</li>
                  <li>신촌세브란스병원 인턴 수료</li>
                  <li>신촌세브란스병원 이비인후과 레지던트 수료</li>
                  <li>이비인후과 전문의 취득</li>
                </ul>
                <p className="font-24 mt-50 mb-20">
                  <strong>학회활동</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">대한이비인후과 학회 정회원</li>
                  <li className="list-mb">연세대학교 Temporal bone dissection 수료</li>
                  <li className="list-mb">대한이과학회 정회원</li>
                  <li>연세대학교 Nose dissection 수료</li>
                </ul>
              </PopupText>
            ) : null}
            {pNum === 5 ? (
              <PopupText className=" text-center">
                <img
                  src="/img/about/about-3-6.png"
                  className="center-block"
                  style={{maxWidth: '100%', width: '200px', height: '200px',borderRadius: '100%', overflow: 'hidden'}}
                  alt="이세은 원장"
                />
                <p className="font-24 mt-50">
                  <strong>이세은 원장 Yi Se Eun</strong>
                </p>
                <p className="font-24 mt-50 mb-20">
                  <strong>주요경력</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li>계명대학교 의과대학 졸업</li>
                  <li>서울아산병원 인턴 수료</li>
                  <li>서울아산병원 이비인후과 레지던트 수료</li>
                  <li>이비인후과 전문의 취득</li>
                  <li>울산대학교 의학석사 졸업</li>
                  <li>서울아산병원 이비인후과 이과 전임의</li>
                </ul>
                <p className="font-24 mt-50 mb-20">
                  <strong>학회활동</strong>
                </p>
                <ul className="list-unstyled font-20 mb-20">
                  <li className="list-mb">대한이비인후과 학회 정회원</li>
                  <li className="list-mb">서울아산병원 Temporal bone dissection 수료</li>
                  <li className="list-mb">서울아산병원 Nose dissection 수료</li>
                  <li className="list-mb">서울아산병원 Head and Neck dissection 수료</li>
                  <li>대한이과학회 정회원</li>
                </ul>
              </PopupText>
            ) : null}
          </Rodal>
        </Centered>
      </div>
    </>
  );
}

export default About2;
